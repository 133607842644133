<template lang="pug">
  div
    out-order
</template>
<script>
export default {
  name: 'Reports',
  components: {
    OutOrder: () => import('./OutOrder'),
  },
}
</script>